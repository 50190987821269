export default {
  tagline: "Das beliebte Kartenspiel, online. 🕵️",
  "create-game": "Spiel erstellen",
  "new-game": "Neues Spiel",
  "back-to-lobby": "Zurück zur Lobby",
  "join-room": "Einem Spiel beitreten",
  "no-public-room":
  "Es gibt derzeit keine öffentlichen Spiele. Erstelle eins und lade Freunde ein!",
  "how-to-play": "Spielanleitung",
  "game-tagline":
  "Codegame ist ein Spiel, bei dem zwei Teams gegeneinander antreten und versuchen mithilfe von Hinweisen Codewörter zu erraten.",
  "see-more": "Mehr anzeigen...",
  "classic-rules": "Klassische Regeln",
  "duet-rules":  "Duett Regeln",
  "support-authors":
  "Wenn dir dieses Spiel gefällt, unterstütze bitte die Autoren und kaufe eine physische Version!",
  "made-with-love": "Mit ♥ gemacht während des Lockdowns",
  "its-open-source": "Es ist <1>Open Source</1>.",
  "check-our-privacy-policy": "Lies unsere <2>Datenschutzerklärung</2>.",
  "choose-options": "Spieleinstellungen",
  mode: "Modus",
  duet: "Duett",
  classic: "Klassisch",
  dictionary: "Wörter",
  "public-game": "Sichtbarkeit",
  private: "Privat",
  public: "Öffentlich",
  "join-game": "Spiel beitreten",
  "placeholder-join": "Spiel beitreten, um zu chatten",
  "give-hint": "Hinweis geben",
  "clicked-on": "klickte auf",
  "become-spymaster": "Geheimdienstchef werden",
  "switch-teams": "Team wechseln",
  "red-adj": "rote",
  "blue-adj": "blaue",
  "start-game-classic":
  "Der <1>{{team}}</1> Geheimdienstchef gibt einen Hinweis, um das Spiel zu beginnen!",
  "see-rules": "Regeln anzeigen",
  "start-game-duet": "Klicke auf eine Karte oder gib einen Hinweis, um das Spiel zu starten!",
  "copied-to-clipboard":"In die Zwischenablage kopiert!",
  "turns-left": "{{ count }} Runde übrig",
  "turns-left_plural": "{{count}} Runden übrig",
  "game-over": "Das Spiel ist vorbei.",
  "winner-team": "Das <1>{{team}}</1> Team gewinnt!",
  "black-card-loss": "Das Spiel ist vorbei. Ihr habt verloren, weil ihr auf eine schwarze Karte geklickt haben.",
  "out-of-time-loss": "Die Zeit ist abgelaufen.",
  won: "Dein Team hat gewonnen!",
  "join-red": "Rot beitreten",
  "join-blue": "Blau beitreten",
  "join-side": "Seite beitreten",
  spectate: "Zuschauen",
  "game-started": "Das Spiel hat begonnen",
  "page-title": "Codegame online spielen",
  "page-description":
  "Spiele Codegame online, ein Mehrspieler-Wörterspiel, das für Handys und Desktop verfügbar ist. Bei diesem Ratespiel treten zwei Teams gegeneinander an und versuchen mithilfe von Hinweisen Codewörter zu erraten.",
  "in-the-room": "Im Raum",
  rules: "Regeln",
  red: "Rot",
  blue: "Blau",
  duetA: "Seite A",
  duetB: "Seite B",
  spectator: "Zuschauer",
  "just-joined": "ist dem Spiel beigetreten!",
  "game-created": "Spiel erstellt.",
  "no-players-yet": "Es sind noch keine Spieler in diesem Team!",
  "player-name-placeholder": "Spielername eingeben",
  cards: "Karten",
  buymeacoffee: "Unterstütze das Spiel, kaufe mir einen Kaffee",
  "positive-reaction-1": "Schön!",
  "positive-reaction-2": "Gut gemacht!",
  "positive-reaction-3": "Starker Zug!",
  "positive-reaction-4": `Ein Punkt!`,
  "positive-reaction-5": "Juhu!",
  "positive-reaction-6": "😎",
  "neutral-reaction-1": "Das ging daneben.",
  "neutral-reaction-2": "Knapp daneben.",
  "neutral-reaction-3": "Nächstes Mal schaffst du es.",
  "neutral-reaction-4": "Das war ein unbeteiligter Zuschauer.",
  "neutral-reaction-5": "Fast!`",
  "neutral-reaction-6": "Zu schade.",
  "black-reaction-1": "Dein Team hat das Spiel verloren!",
  "black-reaction-2": "Das andere Team gewinnt!",
  "black-reaction-3": "Dein Team verliert ☠️",
  "black-reaction-4": "Oh nein, du hast auf einen Attentäter geklickt ☠️",
  "negative-reaction-1": "Ups!",
  "negative-reaction-2": "Oh nein!",
  "negative-reaction-3": `Das andere Team dankt euch.`,
  "negative-reaction-4": "Keine Sorge, Ihr haben euer Bestes gegeben. 💛",
  "negative-reaction-5": "🤦",
  "rules-classic-teams": `Die Spieler werden in zwei Teams aufgeteilt: <1>rot</1> und <3>blau</3>. Ein Spieler jedes Teams wird zu dessen Geheimdienstchef gewählt 🕵️ - alle anderen sind Ermittler.`,
  "rules-classic-grid": `25 zufällige Wortkarten werden in einem 5-mal-5-Raster ausgelegt. Ein Teil dieser Wörter steht für <1>rote</1> Agenten, ein anderer Teil für <3>blaue</3> Agenten, eines für einen <5>Attentäter</5>, und die anderen repräsentieren <7>unbeteiligte Zuschauer</span>. Die Geheimdienstchefs der Teams kennen die Farben der einzelnen Wörter und geben ihrem Team abwechselnd Hinweise, damit sie die Wörter ihrer Farbe erraten.`,
  "rules-classic-hints": `Jeder Hinweis darf nur aus einem einzigen Wort und einer Anzahl von Karten bestehen, auf die er sich bezieht. Der Geheimdienstchef gibt einen Hinweis, der sich auf möglichst viele der Wörter auf den Karten seiner eigenen Agenten bezieht, aber nicht auf andere - er könnte sein Team versehentlich dazu bringen, eine Karte zu wählen, die einen unbeteiligten Zuschauer, einen gegnerischen Agenten oder den Attentäter darstellt.`,
  "rules-hint-restrictions": `Das Hinweiswort kann frei gewählt werden, solange es keines der Wörter ist (und nicht enthält), die zu diesem Zeitpunkt noch offen sind. Phonetische Hinweise sind typischerweise verboten.`,
  "rules-turns": `Die Karten werden aufgedeckt, wenn geraten wird. Ermittler müssen mindestens einen Rateversuch pro Runde tätigen, wobei sie einen Fehlschlag riskieren. Sie können ihren Zug auch zu jedem späteren Zeitpunkt freiwillig beenden. Sie können bis zu der vom Geheimdienstchef angegebenen Zahl plus eins (ein Bonus, um ein zuvor angedeutetes Wort zu finden) raten.`,
  "rules-classic-game-end": `Das Spiel endet, wenn alle Agenten einer Mannschaft identifiziert sind (🏅), oder wenn eine Mannschaft den <1>Attentäter</1> identifiziert hat (☠️).`,
  "rules-duet-objective": `Zwei Spieler arbeiten zusammen, um 15 Wörter auf dem Raster zu finden.`,
  "rules-duet-grid": `25 zufällige Wortkarten werden in einem 5-mal-5-Raster ausgelegt. Ein Teil dieser Wörter steht für <1>grüne</1> Agenten, ein anderer für <3>Attentäter</3>, und die restlichen für <5>unschuldige Zuschauer</5>. Jeder Spieler kennt 9 <7>grüne</7> Wörter, von denen sich einige mit der anderen Seite überschneiden.`,
  "rules-duet-hints": `Die Spieler geben abwechselnd Hinweise, damit der andere Spieler seine grüne Karte errät. Jeder Hinweis darf nur aus einem einzigen Wort und einer Anzahl von Karten bestehen, auf die er sich bezieht. Der Geheimdienstchef gibt einen Hinweis, der sich auf möglichst viele der Wörter auf den Karten seiner eigenen Agenten bezieht, aber nicht auf andere - sie könnten den anderen Spieler versehentlich dazu verleiten, eine Karte zu wählen, die einen unschuldigen Zuschauer oder einen Attentäter darstellt`,
  "rules-duet-game-end": `Das Spiel endet, wenn alle 15 grünen Agenten identifiziert sind (🏅), oder wenn ein Spieler einen <1>Attentäter</1> identifiziert hat (☠️). Das Spiel ist zeitbegrenzt. Beide Spieler haben insgesamt 9 Runden Zeit, um alle Wörter zu finden. Ein Klick auf einen <3>unschuldiger Zuschauer</Spanne> verbraucht einen zusätzlichen Zug.`,
  "long-story-short": "Langer Rede kurzer Sinn",
  "privacy-tldr": `
  <p>Die Partien und Chats verbleiben für eine gewisse Zeit in unserer Datenbank, können aber jederzeit gelöscht werden. Dies ist die einzige Information, die wir speichern.</p>
  <p>Wir verwenden Cookies, um Analysen über die Nutzung der Website (demographische Daten, Dauer der Sitzungen, Art der gespielten Spiele) zu erhalten. Diese Analysen enthalten keine persönlich identifizierbaren Informationen.</p>
  <p> Unser Trackingtool ist Google Analytics.< /p>
  `
}

