export default {
    tagline: "A game of guessing, online. 🕵️‍♂️",
    "create-game": "Създай игра",
    "new-game": "аНова игр",
    "back-to-lobby": "Обратно към лобито",
    "join-room": "Присъедини се към играта",
    "no-public-room":
      "В момента няма публични игри. Създайте игра и поканете приятели!",
    "how-to-play": "Как се играе",
    "game-tagline":
      "Codegame е игра на отгатване, при която отборите се състезават, за да намерят думи, свързани с подсказваща дума, дадена от друг играч.",
    "see-more": "Виж още...",
    "classic-rules": "Класическа",
    "duet-rules": "Дует",
    "support-authors":
      "Тази игра е вдъхновена от механиката на Codenames, но този уебсайт не е свързан с CGE, редактора на Codenames. Ако харесвате тази игра, моля, подкрепете авторите и купете физическа версия.",
    "made-with-love": "Made with ♥ during the lockdown",
    "its-open-source": "It's <1>open-source</1>.",
    "check-our-privacy-policy": "Вижте <2>политика за поверителност</2>.",
    "choose-options": "Изберете опции",
    mode: "Режим",
    duet: "Дует",
    classic: "Класическа",
    dictionary: "Речник",
    "public-game": "Публична игра",
    private: "Частна",
    public: "Публична",
    "join-game": "Присъедини се",
    "placeholder-join": "присъединете се към играта, за да започнете да чатите",
    "give-hint": "дайте подсказка",
    "clicked-on": "щракна върху",
    "become-spymaster": "Станете шпионин",
    "switch-teams": "Сменете отбора",
    "red-adj": "червен",
    "blue-adj": "син",
    "start-game-classic":
      "<1>{{team}}</1> шпионин даде подсказка, за да започне играта!",
    "see-rules": "Виж правилата",
    "start-game-duet": "Кликнете върху карта или дайте подсказка, за да започнете играта!",
    "copied-to-clipboard": "Копирано в клипборда!",
    "turns-left": "{{ count }} останили ходове",
    "turns-left_plural": "{{ count }} останили ходове",
    "game-over": "Играта свърши.",
    "winner-team": "<1>{{team}}</1> отбор печели!",
    "black-card-loss": "Загубихте, защото щракнахте върху черна карта.",
    "out-of-time-loss": "Времето ви свърши!",
    won: "Ти спечели!",
    "join-red": "присъединете се към червените",
    "join-blue": "присъединете се към сините",
    "join-side": "присъединете се",
    spectate: "наблюдавай",
    "game-started": "започнати",
    "page-title": "Codegame - Играйте онлайн",
    "page-description":
      "Играйте онлайн Codegame, мултиплейър игра с думи, достъпна на телефон и настолен компютър. В тази игра на отгатване екипите се състезават, за да намерят карти, свързани с дадената дума-подсказка.",
    "in-the-room": "В стаята",
    rules: "Правила",
    red: "Червен",
    blue: "Син",
    duetA: "Страна A",
    duetB: "Страна B",
    spectator: "Наблюдател",
    "just-joined": "присъединихте се към играта!",
    "game-created": "Играта е създадена.",
    "no-players-yet": "Все още няма играчи в този отбор!",
    "player-name-placeholder": "Въведете име на играча",
    cards: "карти",
    buymeacoffee: "Support Codegame, buy me a coffee",
    "positive-reaction-1": "Добре!",
    "positive-reaction-2": "Добра работа!",
    "positive-reaction-3": "Силен ход.",
    "positive-reaction-4": `Една точка!`,
    "positive-reaction-5": "Ихаа!",
    "positive-reaction-6": "😎",
    "neutral-reaction-1": "Пропусна.",
    "neutral-reaction-2": "За малко.",
    "neutral-reaction-3": "Успех следващия път.",
    "neutral-reaction-4": "Това е жалко.",
    "neutral-reaction-5": `Почти!`,
    "neutral-reaction-6": "Много жалко.",
    "black-reaction-1": "Загуби играта!",
    "black-reaction-2": "Другият отбор печели!",
    "black-reaction-3": "Твоят отбор загуби☠️",
    "black-reaction-4": "О, не, кликнахте на убиеца ☠️",
    "negative-reaction-1": "Оооопс!",
    "negative-reaction-2": "Ооо не!",
    "negative-reaction-3": `Другият отбор ти благодари.`,
    "negative-reaction-4": "Направихте каквото можете 💛.",
    "negative-reaction-5": "🤦‍♂️",
    "rules-classic-teams": `Играчите са разделени на два отбора: <1>червен</1> и <3>син</3>. По един играч от всеки отбор е избран за шпионин 🕵️‍♂️ - останалите са полеви оперативни агенти.`,
    "rules-classic-grid": `Двадесет и пет карти с думи са разпределени на случаен принцип на полето. Част от тези думи представляват <1>червени</1> агенти, друга част - <3>сини</3> агенти, една представлява <5>убиец</5>, а останалите представляват <7>невинни странични наблюдатели</span>. Шпионите на отборите знаят цветовете на всяка дума и се редуват, за да помогнат на своя екип, за да намерят думите на техния цвят.`,
    "rules-classic-hints": `Всеки намек може да се състои само от една дума и няколко карти, за които се отнася. Шпионинът дава подсказка, свързана с колкото се може повече от думите на картите на собствените му агенти, но не и на другите - те може случайно да накарат екипа си да избере карта, представляваща невинен страничен наблюдател, противоположен агент, или убиецът.`,
    "rules-hint-restrictions": `Думата подскака може да бъде избрана свободно, стига да не е (и да не съдържа) нито една от думите на картите с кодови имена, които все още се показват по това време. Фонетичните улики обикновено са забранени.`,
    "rules-turns":
      "Картите се разкриват, докато се правят предположения. Полевите агенти трябва да направят поне едно предположение на ход, рискувайки погрешно предположение и последствията от него. Те могат също да прекратят своя ред доброволно по всяко време след това. Те могат да познаят до номера, даден от шпионина, плюс един (бонус за намиране на предварителна подсказка).",
    "rules-classic-game-end": `Играта приключва, когато са намерят всички агенти на един отбор (🏅) или когато един отбор е намерил <1>убиеца</1> (☠️).`,
    "rules-duet-objective": `Двама играчи си помагат, за да намерят 15 думи на полето.`,
    "rules-duet-grid": `Двадесет и пет карти с думи са разпределени на случаен принцип на полето. Част от тези думи представляват <1>зелени</1> агенти, друга част - <3>убийци</3>, а останалите представляват <5>невинни странични наблюдатели</5>. Всеки играч знае 9 <7>зелени</7> думи, като някои от тях се припокриват с другата страна.`,
    "rules-duet-hints": `Играчите алтернативно дават подсказки, за да накарат другия играч да намери своите зелени карти. Всяка подсказка може да се състои само от една дума и няколко карти, за които се отнася. Шпионинът
     дава подсказка, свързана с колкото се може повече от думите в картите на собствените му агенти, но не и с други - те може случайно да накарат другия играч да избере карта, представляваща невинен наблюдател или убиец .`,
    "rules-duet-game-end": `Играта приключва, когато са намерини всички 15 зелени агенти (🏅) или когато един играч е идентифицирал <1>убиеца</1> (☠️). Играта е с време, което означава, че играчите получават общо 9 завъртания, за да намерят всички думи. Кликването върху <3>невинен наблюдател</span> отнема един допълнителен ход.`,
    "long-story-short": "Накратко",
    "privacy-tldr": `
    <p> Съхраняваме игри и чатове в нашата база данни за известен период от време, но те могат да бъдат изтрити по всяко време. Това е единствената информация, която съхраняваме. </p>
     <p> Използваме бисквитки, за да можем да виждаме анализи за използването на сайта (демографски данни, продължителност на сесиите, видове изиграни игри). Тези анализи не съдържат лична информация. </p>
     <p> Нашият доставчик на анализи е Google Analytics. </p>
  `,
  };
  