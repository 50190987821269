export default {
  tagline: "A game of guessing, online. 🕵️‍♂️",
  "create-game": "Create game",
  "new-game": "New game",
  "back-to-lobby": "Back to lobby",
  "join-room": "Join a room",
  "no-public-room":
    "There are currently no public games. Create one and invite friends!",
  "how-to-play": "How to play",
  "game-tagline":
    "Codegame is a game of guessing where teams compete to find words related to a hint-word given by another player.",
  "see-more": "See more...",
  "classic-rules": "Classic rules",
  "duet-rules": "Duet rules",
  "support-authors":
    "This game is inspired by the mechanics of Codenames, but this website is not affiliated with CGE, the editor of Codenames. If you like this game, please support the authors and buy a physical version.",
  "made-with-love": "Made with ♥ during the lockdown",
  "its-open-source": "It's <1>open-source</1>.",
  "check-our-privacy-policy": "Check our <2>privacy policy</2>.",
  "choose-options": "Choose your options",
  mode: "Mode",
  duet: "Duet",
  classic: "Classic",
  dictionary: "Dictionary",
  "public-game": "Public game",
  private: "Private",
  public: "Public",
  "join-game": "Join game",
  "placeholder-join": "join game to start chatting",
  "give-hint": "give hint",
  "clicked-on": "clicked on",
  "become-spymaster": "Become spymaster",
  "switch-teams": "Switch teams",
  "red-adj": "red",
  "blue-adj": "blue",
  "start-game-classic":
    "The <1>{{team}}</1> spymaster gives a hint to start the game!",
  "see-rules": "See rules",
  "start-game-duet": "Click on a card or give a hint to start the game!",
  "copied-to-clipboard": "Copied to clipboard!",
  "turns-left": "{{ count }} turn left",
  "turns-left_plural": "{{ count }} turns left",
  "game-over": "The game is over.",
  "winner-team": "The <1>{{team}}</1> team wins!",
  "black-card-loss": "You lost because you clicked on a black card.",
  "out-of-time-loss": "You ran out of time!",
  won: "You won!",
  "join-red": "join red",
  "join-blue": "join blue",
  "join-side": "join side",
  spectate: "spectate",
  "game-started": "started",
  "page-title": "Codegame - Play Online",
  "page-description":
    "Play Codegame online, a multiplayer word game available on phone and desktop. In that game of guessing, teams compete to find cards related to the given hint-word.",
  "in-the-room": "In the room",
  rules: "Rules",
  red: "Red",
  blue: "Blue",
  duetA: "Side A",
  duetB: "Side B",
  spectator: "Spectating",
  "just-joined": "joined the game!",
  "game-created": "The game was created.",
  "no-players-yet": "There are no players in this team yet!",
  "player-name-placeholder": "Enter player name",
  cards: "cards",
  buymeacoffee: "Support Codegame, buy me a coffee",
  "positive-reaction-1": "Nice!",
  "positive-reaction-2": "Good job!",
  "positive-reaction-3": "Strong move.",
  "positive-reaction-4": `One point!`,
  "positive-reaction-5": "Yay!",
  "positive-reaction-6": "😎",
  "neutral-reaction-1": "That's a miss.",
  "neutral-reaction-2": "Close enough.",
  "neutral-reaction-3": "You'll get it next time.",
  "neutral-reaction-4": "That was a civilian.",
  "neutral-reaction-5": `Almost!`,
  "neutral-reaction-6": "Too bad.",
  "black-reaction-1": "You just lost the game!",
  "black-reaction-2": "The other team wins!",
  "black-reaction-3": "Your team loses ☠️",
  "black-reaction-4": "Oh no, you clicked on an assassin ☠️",
  "negative-reaction-1": "Whoops!",
  "negative-reaction-2": "Oh no!",
  "negative-reaction-3": `The other team thanks you.`,
  "negative-reaction-4": "Don't worry you tried your best 💛.",
  "negative-reaction-5": "🤦‍♂️",
  "rules-classic-teams": `Players are split into two teams: <1>red</1> and <3>blue</3>. One player of each team is selected as the team's spymaster 🕵️‍♂️ - the others are field operatives.`,
  "rules-classic-grid": `Twenty-five word cards are randomly laid out in a grid. A number of these words represent <1>red</1> agents, a number represent <3>blue</3> agents, one represents an <5>assassin</5>, and the others represent <7>innocent bystanders</span>. The teams' spymasters know the colors of each word, and take turns to hint their team for them to find the words of their color.`,
  "rules-classic-hints": `Each hint may only consist of one single word and a number of cards it relates to. The spymaster gives a hint that is related to as many of the words on his/her own agents' cards as possible, but not to any others – they might accidentally lead their team to choose a card representing an innocent bystander, an opposing agent, or the assassin.`,
  "rules-hint-restrictions": `The hint's word can be chosen freely, as long as it is not (and does not contain) any of the words on the code name cards still showing at that time. Phonetic clues are typically forbidden.`,
  "rules-turns":
    "The cards are revealed as guesses are made. Field operatives must make at least one guess per turn, risking a wrong guess and its consequences. They may also end their turn voluntarily at any point thereafter. They can guess up to the number given by the spymaster, plus one (a bonus to find a previously hinted word).",
  "rules-classic-game-end": `The game ends when all of one team's agents are identified (🏅), or when one team has identified the <1>assassin</1> (☠️).`,
  "rules-duet-objective": `Two players cooperate to find 15 words on the board.`,
  "rules-duet-grid": `Twenty-five word cards are randomly laid out in a grid. A number of these words represent <1>green</1> agents, a number represent <3>assassins</3>, and the others represent <5>innocent bystanders</5>. Each player knows 9 <7>green</7> words, some of them overlapping with the other side.`,
  "rules-duet-hints": `Players alternatively give hints to make the other player find their green cards. Each hint may only consist of one single word and a number of cards it relates to. The spymaster gives a hint that is related to as many of the words on his/her own agents' cards as possible, but not to any others – they might accidentally lead the other player to choose a card representing an innocent bystander, or an assassin.`,
  "rules-duet-game-end": `The game ends when all of the 15 green agents are identified (🏅), or when one player has identified an <1>assassin</1> (☠️). The game is timed, which means that players get a total of 9 turns to find all the words. Clicking on an <3>innocent bystander</span> consumes one extra turn.`,
  "long-story-short": "Long story short",
  "privacy-tldr": `
  <p>We keep games and chats in our database for some period of time, but they may be deleted at any moment. This is the only information we store.</p>
  <p>We use cookies to be able to see analytics about the site's usage (demographics, length of sessions, types of games played). Those analytics do not contain personally identifiable information.</p>
  <p>Our analytics provider is Google Analytics.</p>
`,
};
